<template>
    <v-card class="mx-auto mt-16" max-width="640" outlined>
        <v-card-title class="text-h5 light-blue dark">
          Fehler 404 - Die gesuchte Seite wurde nicht gefunden!
        </v-card-title>

        <v-card-text>
            <div style="text-align: center; font-size: 500px; min-height: 550px; padding-top: 260px;">😵</div>
        </v-card-text>


    </v-card>
</template>


<script>
export default {
    data: function() {
        return ({
        });
    },
    computed: {
    },
    methods : {
    },
}
</script>
